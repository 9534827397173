<template>
  <product title="Amazfit 米动健康手表"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="699"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    buy-link="https://j.youzan.com/JIrjKh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'health-watch',
  components: {
    Product
  },
  data () {
    return {
      slogan: '7 x 24 小时不间断心率监测 | ECG 非处方心电图<br> AI 本地甄别心律不齐(含房颤) <br/> 亲友关注',
      colors: [
        '黑色',
        '粉色',
        '棕色'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/health-watch/swiper/1.png',
          CDN_URL + '/images/product/health-watch/swiper/2.png',
          CDN_URL + '/images/product/health-watch/swiper/3.png',
          CDN_URL + '/images/product/health-watch/swiper/4.png'
        ],
        [
          CDN_URL + '/images/product/health-watch/swiper/9.png',
          CDN_URL + '/images/product/health-watch/swiper/10.png',
          CDN_URL + '/images/product/health-watch/swiper/11.png',
          CDN_URL + '/images/product/health-watch/swiper/12.png'
        ],
        [
          CDN_URL + '/images/product/health-watch/swiper/5.png',
          CDN_URL + '/images/product/health-watch/swiper/6.png',
          CDN_URL + '/images/product/health-watch/swiper/7.png',
          CDN_URL + '/images/product/health-watch/swiper/8.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/1_619da143da235.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/2_619da17dccaf5.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/4.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/8_619da17dbc34a.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/9.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/10.jpg',
        // 'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/11.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/12_619da17dde528.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/13.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/14.jpg',
        'https://site-cdn.huami.com/files/amazfit/mobile/health-watch/15.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
